import React from "react";
import styles from "./style.module.scss";
import Image from "next/image";
import { motion } from "framer-motion";
import SharedButton from "@components/severalbrands/sharedButton";
const MainSection = () => {
    return (
        <div className={`${styles["bgStyle"]} px-4`}>
            <div className="3xl:pt-28 3xl:pb-64 lg:pt-10 lg:pb-48 pt-20 pb-32">
                <motion.div
                    transition={{ duration: 1.5 }}
                    initial={{ opacity: 0, y: 150 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                >
                    <div
                        className={` mx-auto w-[300px] h-[185px]  md:w-[643px] md:min-w-[643px] md:h-[402px] 3xl:w-[643px] 3xl:min-w-[643px] 3xl:h-[402px] relative `}
                    >
                        <Image
                            src={"/severalbrands/assets/images/errorImg.png"}
                            alt="img"
                            layout="fill"
                            objectFit="contain"
                            priority
                        />
                    </div>
                    <h3 className="lg:mt-16 mt-8 font-light text-[#4E4E4E] 3xl:text-2xl  lg:text-xl text-base   mx-auto w-fit">
                        OOPS! sorry, we could not find the page
                    </h3>
                    <div className="mt-6  w-fit mx-auto ">
                        <SharedButton href="/">Go Back Home</SharedButton>
                    </div>
                </motion.div>
            </div>
        </div>
    );
};
export default MainSection;
