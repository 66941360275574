export type PostType = {
    id: number;
    title: string;
    excerpt: string;
    body: string;
    updatedAt: string;
    createdAt: string;
    image: null | { fullPath: string; caption: string };
    url: {
        url: string;
        isMain: boolean;
        main: {
            url: string;
        };
    };
    tags: { id: number; name: string }[];
    metatags: {
        key: string;
        value: string;
    }[];
    category: {
        id: number;
        name: string;
        label: string;
    };
};

export const POST_BY_ID = `
    query PostById($id: Int) {
        post(id: $id) {
            id
            title
            body
            excerpt
            updatedAt
            category{
                id
                name
                label
              }
            metatags {
                key
                value
            }
            image{
                fullPath
                caption
              }
            tags{
                id
                name
              }  
            }
    }
`;
export const ALL_POSTS = `
    query allPosts(
         $domainId: Int
         $published: Boolean
         $offset: [Int]
         $limit: [Int]
    ) {
        allPosts(
            published: $published
            isDeleted: false
            orderByDesc: createdAt
            domainId: $domainId
            offset: $offset
            limit: $limit
        ) {
            id
            title
            updatedAt
            createdAt
            category{
                id
                name
                label
              }
            image{
                fullPath
              }
            url {
                url
                isMain
                main {
                  url
                }
              }
       }
    }
`;
export const ALL_URL = `
    query allUrls($model: String) {
        allUrls(
            model: $model
         
            isDeleted: false
            isActive: true
        ) {
            model
            modelId
            isMain
            language
            title
            url
            main {
                url
                redirectionStatusCode
            }
        }
    }
`;
export const DOMAIN_INFO = `
query domain($domainName: String) {
    domain(name: $domainName, isDeleted: false, isActive: true) {
        id
        name
        title
        niceName
        config
        recaptchaConfig
        description
        gtmId
        isInspectorEnabled
        enableTrustedForm
        enableJornayaId
        analyticsCodeId
        twitterPixel
        clickyCodeId
        textBlocks(isActive:true) {
           id
           isActive
           label
           position
           text
        }
        metatags {
            key
            value
        }
        logo{
            fullPath
          }
          type
        menus {
          id
          name
          slug
          links(orderBy: order) {
            id
            title
            link
            parentId
          }
        }
      }
}
`;
export const DOMAIN_CATEGORIES = `
    query allCategories($domainId: Int) {
        domain(id: $domainId) {
            categories(
                limit: 100
                orderBy: createdAt
                isPublished: true
                isDeleted: false
            ) {
                id
                title
                name
                slug
                label
                slugAlias
            }
        }
    }
`;
