import React, { ReactElement } from "react";
export type Inputs = {
    firmName: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    city: string;
    body: string;
    interestedIn: string[];
};
import { useDomainContext } from "@hooks/useDomainContext";
import ContactUsForm from "@components/shared/contactUs/contactUsForm";
import { ContactUsField } from "@lib/shared/types";

export default function ContactUs({
    fields,
    className,
    legend,
    tcpa,
    submitButtonMessage,
    classes,
}: {
    fields: ContactUsField[];
    className?: string;
    legend?: string;
    tcpa?: string;
    classes?: {
        formClassName?: string;
        fieldClassName?: string;
        fieldWrapperClassName: string;
        submitButtonClassName?: string;
        thankYouStepClassName?: string;
        fieldErrorClassName?: string;
        selectFieldClassName?: string;
        checkBoxClassName?: string;
        checkboxGroupClassName?: string;
    };
    submitButtonMessage?: string;
}): ReactElement {
    const { domain } = useDomainContext();

    return (
        <div
            className={`text-center w-full mx-auto flex flex-col items-center justify-center gap-4 lg:min-w-[900px] ${
                className ?? ""
            }`}
        >
            <legend className="lg:text-4xl text-2xl text-white font-bold">
                {legend}
            </legend>
            <div className="w-full">
                <ContactUsForm
                    primaryColor={"#dd1f26"}
                    secondaryColor="#000000"
                    colors={{
                        thankYouColorText: "#fff",
                        thankYouColorIcon: "#fff",
                    }}
                    classes={classes}
                    domainInfo={domain}
                    fields={fields}
                    severalBrandsRecaptcha
                    submitButtonTitle={submitButtonMessage}
                />
                {tcpa && (
                    <div className="text-[#bdbdbd] text-left border-t border-slate-600 p-4 mt-4">
                        {tcpa}
                    </div>
                )}
            </div>
        </div>
    );
}
