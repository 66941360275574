import Modal from "@components/torts/shared/modal";
import React, { ReactElement } from "react";
import Portal from "@components/torts/portal";
import styles from "./styles.module.scss";
import CloseIcon from "@components/torts/shared/modal/closeIcon";
import ContactUsForm from "../contactUsForm";
import { mainContactUsForm } from "@lib/shared/contactUsForm";
import { useModalContext } from "@components/torts/modalContext";

export default function ContactUsModal(): ReactElement {
    const { modalOpen, setModalOpen } = useModalContext();

    return modalOpen ? (
        <Portal>
            <Modal
                onModalClose={() => setModalOpen(false)}
                backdropClassName="bg-opacity-95 bg-black p-5 flex md:items-start pt-4 lg:pt-16 justify-center"
                closeOnBackdropClick={false}
            >
                <button
                    className={styles["close-button"]}
                    type="button"
                    onClick={() => setModalOpen(false)}
                >
                    <CloseIcon color="#fff" />
                </button>
                <ContactUsForm
                    classes={{
                        formClassName: styles.form,
                        fieldWrapperClassName: styles.fieldWrapper,
                        fieldErrorClassName: styles.errorMessage,
                        submitButtonClassName: styles.submitButton,
                        checkboxGroupClassName: styles.checkBoxGroup,
                        fieldClassName: styles.field,
                        thankYouStepClassName: styles.thankYouStep,
                    }}
                    fields={[
                        {
                            name: "firmName",
                            placeholder: "Firm Name",
                            inputType: "text",
                            validation: {
                                required: true,
                                rules: {
                                    type: "isEmpty",
                                    characterCount: {
                                        min: 1,
                                        max: 255,
                                    },
                                },
                            },
                            label: "",
                            type: "text",
                            isExtraField: true,
                        },
                        {
                            placeholder: "First Name",
                            name: "firstName",
                            inputType: "text",
                            validation: {
                                required: true,
                                rules: {
                                    type: "isEmpty",
                                    characterCount: {
                                        min: 1,
                                        max: 255,
                                    },
                                },
                            },
                            label: "",
                            type: "text",
                        },
                        {
                            placeholder: "Last Name",
                            name: "lastName",
                            inputType: "text",
                            validation: {
                                required: true,
                                rules: {
                                    type: "isEmpty",
                                    characterCount: {
                                        min: 1,
                                        max: 255,
                                    },
                                },
                            },
                            label: "",
                            type: "text",
                        },
                        { ...mainContactUsForm.email, label: "" },

                        {
                            ...mainContactUsForm.phoneNumber,
                            label: "",
                        },
                        {
                            name: "city",
                            placeholder: "City",
                            inputType: "text",
                            validation: {
                                required: true,
                                rules: {
                                    type: "isEmpty",
                                    characterCount: {
                                        min: 1,
                                        max: 255,
                                    },
                                },
                            },
                            label: "",
                            type: "text",
                            isExtraField: true,
                        },

                        {
                            ...mainContactUsForm.description,
                            label: "",
                            rows: 5,
                            placeholder: "Tell us how we can help you succeed.",
                        },
                        {
                            ...mainContactUsForm.subject,
                            defaultValue: `Torts.com`,
                            label: "",
                            inputType: "hidden",
                        },
                    ]}
                    legend="Contact Us for a Free Consultation."
                    submitButtonMessage="Contact Us"
                />
            </Modal>
        </Portal>
    ) : (
        <></>
    );
}
