/* eslint-disable no-console */
import {
    PostType,
    ALL_POSTS,
    ALL_URL,
    POST_BY_ID,
    DOMAIN_INFO,
    DOMAIN_CATEGORIES,
} from "@components/severalbrands/gqlQueries";
import { Image } from "@lib/shared/types";
import axios from "axios";
export type AllPostsRes = {
    data: {
        allPosts: PostType[];
    };
};
type AllUrlsRes = {
    data: {
        allUrls: {
            isMain: boolean;
            url: string;
            main: { url: string };
            modelId: string;
            title: string;
        }[];
    };
};
type PostByIdRes = {
    data: {
        post: PostType;
    };
};
export type DomainInfo = {
    id: number;
    name: string;
    niceName: string;
    title: string;
    recaptchaConfig: { key: string; secret: string };
    logo: Image | null;
    menus?: {
        id: number;
        name: string;
        slug: string;
        links: { id: number; title: string; link: string }[];
    }[];
    menusList?: {
        name: string;
        slug: string;
        items: { title: string; link: string }[];
    }[];
    config: {
        sliderLogos?: { [key: string]: { path: string; slug: string }[] };
    };
    metatags: {
        key: string;
        value: string;
    }[];
    description: string;
    textBlocks?: {
        id: number;
        isActive: boolean;
        label: string;
        position: string;
        text: string;
    };
};
type DomainInfoRes = {
    data: {
        domain: DomainInfo;
    };
};
type CategoriesRes = {
    data: {
        domain: {
            categories: {
                id: number;
                name: string;
                label?: string;
                title?: string;
                slug: string;
                slugAlias?: string;
            }[];
        };
    };
};
export const getAllPost = async (
    offset: number,
    limit: number,
    domainId: number,
    domainName?: string,
) => {
    try {
        const response = await axios.post<AllPostsRes>(
            `${process.env.NEXT_PUBLIC_GRAPHQL_URL as string}&apiKey=${
                process.env.NEXT_PUBLIC_GRAPHQL_API_KEY as string
            }`,
            {
                query: ALL_POSTS,
                variables: {
                    domainId: domainId,
                    published: true,
                    offset: offset,
                    limit: limit,
                },
            },
            {
                headers: {
                    "X-language": "en",
                    "X-DOMAIN-NAME":
                        domainName ||
                        (process.env
                            .NEXT_PUBLIC_SEVERAL_BRANDS_X_DOMAIN as string),
                },
            },
        );
        return response;
    } catch (error) {
        return null;
    }
};
export const getAllUrls = async (domainName?: string) => {
    try {
        const response = await axios.post<AllUrlsRes>(
            `${process.env.NEXT_PUBLIC_GRAPHQL_URL as string}&apiKey=${
                process.env.NEXT_PUBLIC_GRAPHQL_API_KEY as string
            }`,
            {
                query: ALL_URL,
                variables: {
                    model: "posts",
                },
            },
            {
                headers: {
                    "X-language": "en",
                    "X-DOMAIN-NAME":
                        domainName ||
                        (process.env
                            .NEXT_PUBLIC_SEVERAL_BRANDS_X_DOMAIN as string),
                },
            },
        );
        return response;
    } catch (error) {
        return null;
    }
};
export const getPostById = async (id: number, domainName?: string) => {
    try {
        const response = await axios.post<PostByIdRes>(
            `${process.env.NEXT_PUBLIC_GRAPHQL_URL as string}&apiKey=${
                process.env.NEXT_PUBLIC_GRAPHQL_API_KEY as string
            }`,
            {
                query: POST_BY_ID,
                variables: {
                    id: id,
                },
            },
            {
                headers: {
                    "X-language": "en",
                    "X-DOMAIN-NAME":
                        domainName ||
                        (process.env
                            .NEXT_PUBLIC_SEVERAL_BRANDS_X_DOMAIN as string),
                },
            },
        );
        return response;
    } catch (error) {
        return null;
    }
};
export const getDomainInfo = async (domainName?: string) => {
    try {
        const response = await axios.post<DomainInfoRes>(
            `${process.env.NEXT_PUBLIC_GRAPHQL_URL as string}&apiKey=${
                process.env.NEXT_PUBLIC_GRAPHQL_API_KEY as string
            }`,
            {
                query: DOMAIN_INFO,
                variables: {
                    domainName:
                        domainName ||
                        (process.env
                            .NEXT_PUBLIC_SEVERAL_BRANDS_X_DOMAIN as string),
                },
            },
            {
                headers: {
                    "X-language": "en",
                    "X-DOMAIN-NAME":
                        domainName ||
                        (process.env
                            .NEXT_PUBLIC_SEVERAL_BRANDS_X_DOMAIN as string),
                },
            },
        );

        return response;
    } catch (error) {
        return null;
    }
};
export const getDomainCategories = async (
    domainId: number,
    domainName?: string,
) => {
    try {
        const response = await axios.post<CategoriesRes>(
            `${process.env.NEXT_PUBLIC_GRAPHQL_URL as string}&apiKey=${
                process.env.NEXT_PUBLIC_GRAPHQL_API_KEY as string
            }`,
            {
                query: DOMAIN_CATEGORIES,
                variables: {
                    domainId: domainId,
                },
            },
            {
                headers: {
                    "X-language": "en",
                    "X-DOMAIN-NAME":
                        domainName ||
                        (process.env
                            .NEXT_PUBLIC_SEVERAL_BRANDS_X_DOMAIN as string),
                },
            },
        );

        return response;
    } catch (error) {
        return null;
    }
};
