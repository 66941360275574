// import Spinner from "@components/shared/spinner";
import React, { ReactElement, ReactNode, useEffect, useRef } from "react";
import styles from "./style.module.scss";

interface Props {
    className?: string;
    children: ReactNode;
    onModalClose?: () => void;
    backdropClassName?: string;
    closeOnBackdropClick?: boolean;
}

export default function Modal({
    className,
    children,
    onModalClose,
    backdropClassName,
    closeOnBackdropClick = true,
}: Props): ReactElement {
    const modalRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (closeOnBackdropClick) {
            modalRef.current?.focus();
        }
    }, []);

    return (
        <div
            className={`fixed z-50 inset-0 overflow-y-auto duration-[.4s] min-h-screen text-center ${
                styles.fadeIn
            } ${backdropClassName || ""}`}
        >
            <div
                className={`${className ?? ""} ${styles["scale-in-center"]}`}
                role="dialog"
                ref={modalRef}
                aria-modal="true"
                aria-labelledby="modal-headline"
                onBlur={() => {
                    if (closeOnBackdropClick && onModalClose) {
                        onModalClose?.();
                    }
                }}
                tabIndex={0}
            >
                {children}
            </div>
        </div>
    );
}
