import { ReactElement } from "react";
import { DomainInfo } from "@components/severalbrands/utils/services";
import React from "react";
import SeveralBrandsWrapper from "@components/layouts/severalBrandsWrapper";
import MetaTags from "@components/severalbrands/metaTags";
import { getKeyWordsForMetaTags } from "@components/severalbrands/utils";
import MainSection from "./mainSection";
export default function ErrorPage({
    domain,
}: {
    domain: DomainInfo;
}): ReactElement {
    return (
        <SeveralBrandsWrapper domainInfo={domain}>
            <MetaTags
                title="404 | SeveralBrands.com"
                description="Several Brands LLC is a Performance Marketer Firm. We generate leads, calls and live transfers for local businesses. Furthermore, we promote various products from several affiliate networks."
                keyWords={getKeyWordsForMetaTags(domain)}
            />
            <MainSection />
        </SeveralBrandsWrapper>
    );
}
