import React from "react";
import Image from "next/image";
import TortsWrapper from "@components/layouts/tortsWrapper";
import ContactUsModal from "@components/torts/contactUsModal";
const ErrorPage = () => {
    return (
        <TortsWrapper>
            <div className="max-w-5xl flex flex-col items-center m-auto gap-10 my-3 mb-12">
                <div className="relative sm:w-[600px] w-[300px] h-[200px] sm:h-[400px]">
                    <Image
                        src="/torts/assets/images/404.png"
                        alt="404"
                        objectFit="contain"
                        layout="fill"
                    />
                </div>
                <div className="text-center flex flex-col gap-2">
                    <p className=" text-xl md:text-3xl mt-8 text-red-500">
                        Error 404 - Page Not Found
                    </p>
                    <p className="text-base md:text-xl mt-5  h-full">
                        The Page You Requested Couldn't Be Found
                    </p>
                </div>
            </div>
            <ContactUsModal />
        </TortsWrapper>
    );
};

export default ErrorPage;
