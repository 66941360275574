import { ReactElement, ReactNode } from "react";
import styles from "./style.module.scss";
export default function SharedButton({
    children,
    href,
    pdf,
    regularButton = false,
    onCLick,
    scrollButton = false,
}: {
    children: ReactNode;
    href?: string;
    pdf?: boolean;
    regularButton?: boolean;
    onCLick?: VoidFunction;
    scrollButton?: boolean;
}): ReactElement {
    const scrollToTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };
    const buttonClassName = `  text-white  py-3 sm:px-16 px-7 sm:text-lg 3xl:text-2xl font-bold rounded-lg cursor-pointer block w-fit ${styles["btnStyle"]}`;
    return (
        <>
            {regularButton ? (
                <button
                    onClick={scrollButton ? scrollToTop : onCLick}
                    className={buttonClassName}
                >
                    {children}
                </button>
            ) : (
                <a
                    href={href}
                    target={pdf ? "_blank" : "_self"}
                    rel="noreferrer"
                    className={buttonClassName}
                >
                    {children}
                </a>
            )}
        </>
    );
}
