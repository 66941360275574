import React, { ReactElement, ReactNode } from "react";
import Head from "next/head";
import { useEffect } from "react";
import { useRouter } from "next/router";
import { setVisitorInfo } from "@lib/severalBrandsHelpers/helpers";
import { getUserSession } from "src/api/severalBrandsSession";
import Header from "@components/torts/shared/header";
import Footer from "@components/torts/shared/footer";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useDomainContext } from "@hooks/useDomainContext";
import { ModalProvider } from "@components/torts/modalContext";

export default function TortsWrapper({
    children,
}: {
    children: ReactNode;
}): ReactElement {
    const router = useRouter();

    const fetchData = async () => {
        const data = await getUserSession(
            window.location.href,
            document.referrer,
        );

        if (data && data.data) {
            //@ts-ignore
            setVisitorInfo(data.data);
        }
    };
    useEffect(() => {
        fetchData().catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error);
        });
    }, [router.asPath]);

    const { domain } = useDomainContext();

    return (
        <>
            <Head>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" />

                <link
                    href="https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&display=swap"
                    rel="stylesheet"
                />
            </Head>
            <style jsx global>{`
                body {
                    overflow-x: unset;
                }
            `}</style>
            <GoogleReCaptchaProvider
                reCaptchaKey={
                    domain?.recaptchaConfig?.key ??
                    (process.env
                        .NEXT_PUBLIC_SEVERAL_BRANDS_GOOGLE_RECAPTCHA_SITE_KEY as string)
                }
                container={{
                    parameters: {
                        badge: "inline", // optional, default undefined
                        theme: "dark", // optional, default undefined
                    },
                }}
            >
                <ModalProvider>
                    <Header />
                    <main>{children}</main>
                    <Footer />
                </ModalProvider>
            </GoogleReCaptchaProvider>
        </>
    );
}
