import React, { ReactElement, useState, useMemo } from "react";
import Link from "next/link";
import styles from "./style.module.scss";
import TortsLogo from "./logo";
import { useRouter } from "next/router";
import { useDomainContext } from "@hooks/useDomainContext";
import { useModalContext } from "@components/torts/modalContext";

export default function Header(): ReactElement {
    const [isOpened, setOpened] = useState(false);
    const { domain } = useDomainContext();
    const { setModalOpen } = useModalContext();

    const domainConfig = domain?.config;

    const { pathname } = useRouter();

    const isAbsolute = useMemo(
        () =>
            pathname === "/torts" ||
            pathname.includes("[category]" || pathname === "/join-us"),
        [pathname],
    );
    const showFreeConsultationInHeaderCondition =
        !pathname.includes("[category]") &&
        pathname !== "/torts/join-us" &&
        pathname !== "/torts/privacy-policy" &&
        pathname !== "/torts/terms-and-conditions" &&
        pathname !== "/torts/404";

    const links = useMemo(
        () => [
            ...(pathname === "/torts"
                ? [
                      {
                          href: "#hot_torts",
                          label: "Cases",
                      },
                  ]
                : []),
            ...(showFreeConsultationInHeaderCondition
                ? [
                      {
                          label: "Free Consultation",
                          onClick: () => {
                              setModalOpen(true);
                          },
                      },
                  ]
                : []),
        ],
        [pathname],
    );

    return (
        <header className={`${styles.header} flex`}>
            <nav
                className={`flex items-center justify-between flex-wrap top-0 left-0 index-1 w-full z-10 p-6 bg-black ${
                    isAbsolute ? "absolute bg-opacity-80" : "relative"
                }`}
            >
                <div className="flex items-center justify-between flex-wrap w-full max-w-7xl mx-auto">
                    <div className="flex items-center flex-shrink-0 text-white mr-6">
                        <Link href="/">
                            <a>
                                <TortsLogo color="white" />
                            </a>
                        </Link>
                    </div>
                    <div className="block md:hidden">
                        <button
                            className="flex items-center"
                            onClick={() => {
                                setOpened((prev) => !prev);
                            }}
                        >
                            <svg
                                className="fill-current h-6 w-6"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <title>Menu</title>
                                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                            </svg>
                        </button>
                    </div>
                    <div
                        className={`w-full flex-grow md:flex md:items-center md:w-auto hidden ${
                            isOpened ? styles.active : ""
                        }`}
                    >
                        <div className="text-sm md:flex-grow flex flex-col items-center gap-5 my-10 md:my-0 md:gap-8  md:flex-row justify-center text-white">
                            {links.map((link) =>
                                link.href ? (
                                    <Link
                                        href={link.href || ""}
                                        key={link.label}
                                    >
                                        <a
                                            className={`${styles["nav-link"]} block mt-4 md:inline-block md:mt-0 mr-4 cursor-pointer`}
                                        >
                                            {link.label}
                                        </a>
                                    </Link>
                                ) : (
                                    <span
                                        className={`${styles["nav-link"]} block mt-4 md:inline-block md:mt-0 mr-4 cursor-pointer`}
                                        onClick={link.onClick}
                                        key={link.label}
                                    >
                                        {link.label}
                                    </span>
                                ),
                            )}
                        </div>
                        <div
                            className={`${styles["call-button"]} mt-4 md:mt-0`}
                        >
                            <svg
                                width="17"
                                height="18"
                                viewBox="0 0 17 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M16.6116 13.2126L14.7622 11.3631C14.2443 10.8452 13.4306 10.8452 12.9868 11.3631L11.1374 13.1385C9.80582 12.9165 8.25229 12.1028 6.92078 10.7713C5.51519 9.36574 4.70156 7.81234 4.47955 6.40675L6.25491 4.63139C6.7728 4.18754 6.7728 3.37373 6.25491 2.85602L4.33157 1.00658C3.88772 0.488692 3.07391 0.488692 2.63006 1.00658C1.96419 1.67245 1.37241 2.26423 0.706717 2.85602C-1.14273 4.77937 0.854664 9.29166 4.18359 12.7686C7.73454 16.3934 12.7646 18.9086 14.7621 16.9113C15.3539 16.2455 15.9457 15.6537 16.6116 14.988C17.1295 14.4701 17.1295 13.7303 16.6116 13.2126L16.6116 13.2126ZM15.4281 8.18194C15.4281 8.47784 15.724 8.77373 16.0198 8.77373C16.3898 8.77373 16.6857 8.47783 16.6857 8.18194C16.6857 7.2202 16.4637 6.2586 16.0939 5.44479C15.724 4.55709 15.2062 3.74329 14.5404 3.07764C13.8745 2.41177 13.0609 1.89406 12.1732 1.52411C11.3594 1.15415 10.3979 0.932325 9.43609 0.932325C9.14019 0.932325 8.8443 1.22822 8.8443 1.59819C8.8443 1.89409 9.1402 2.18998 9.43609 2.18998C10.2499 2.18998 10.9896 2.33793 11.7294 2.63383C12.4691 2.92973 13.135 3.37356 13.6527 3.96539C14.2445 4.48328 14.6884 5.14897 14.9843 5.88874C15.2802 6.62846 15.4281 7.36823 15.4281 8.18203L15.4281 8.18194ZM13.0609 8.32989C13.0609 8.69985 13.3568 8.99576 13.6527 8.99576C14.0226 8.99576 14.2445 8.69986 14.2445 8.32989C14.2445 7.66403 14.1704 7.07224 13.8745 6.40655C13.6525 5.81476 13.2827 5.29683 12.8389 4.77912C12.321 4.33526 11.8032 3.96531 11.2114 3.74348C10.5456 3.44758 9.95379 3.37352 9.2881 3.37352C8.91814 3.37352 8.62223 3.59553 8.62223 3.96531C8.62223 4.2612 8.91813 4.55709 9.2881 4.55709C9.73195 4.55709 10.2498 4.63115 10.6937 4.85299C11.1375 5.00094 11.5814 5.29685 11.9513 5.6668C12.3213 6.03675 12.5431 6.4806 12.7651 6.92445C12.9872 7.3683 13.061 7.81216 13.061 8.33004L13.0609 8.32989Z"
                                    fill="white"
                                />
                            </svg>
                            <a
                                href={`tel:${
                                    domainConfig?.header_phone_number ??
                                    "(929) 387-3001"
                                }`}
                            >
                                {domainConfig?.header_phone_number ??
                                    "(929) 387-3001"}
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
}
