import {
    createContext,
    ReactElement,
    ReactNode,
    useContext,
    useState,
} from "react";

const ModalContext = createContext<{
    modalOpen: boolean;
    setModalOpen: (open: boolean) => void;
}>({
    modalOpen: false,
    setModalOpen: () => {},
});

export const ModalProvider = (props: { children: ReactNode }): ReactElement => {
    const [modalOpen, setModalOpen] = useState(false);

    return (
        <ModalContext.Provider
            value={{
                modalOpen,
                setModalOpen,
            }}
        >
            {props.children}
        </ModalContext.Provider>
    );
};

export const useModalContext = () => useContext(ModalContext);
