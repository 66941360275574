/**
 * NOTE: This requires `@sentry/nextjs` version 7.3.0 or higher.
 *
 * NOTE: If using this with `next` version 12.2.0 or lower, uncomment the
 * penultimate line in `CustomErrorComponent`.
 *
 * This page is loaded by Nextjs:
 *  - on the server, when data-fetching methods throw or reject
 *  - on the client, when `getInitialProps` throws or rejects
 *  - on the client, when a React lifecycle method throws or rejects, and it's
 *    caught by the built-in Nextjs error boundary
 *
 * See:
 *  - https://nextjs.org/docs/basic-features/data-fetching/overview
 *  - https://nextjs.org/docs/api-reference/data-fetching/get-initial-props
 *  - https://reactjs.org/docs/error-boundaries.html
 */

import { captureUnderscoreErrorException } from "@sentry/nextjs";
import NextErrorComponent from "next/error";
import { postNotFoundPage } from "src/api";
import ErrorPage from "@components/severalbrands/errorPage";
import ErrorPageTorts from "@components/torts/errorPage";
import initialCall from "@components/severalbrands/utils/initialCall"; // severalbrands.com initial call file
const customErrorPageDomains = ["severalbrands.com", "torts.com"];
const CustomErrorComponent = (props) => {
    // If you're using a Nextjs version prior to 12.2.1, uncomment this to
    // compensate for https://github.com/vercel/next.js/issues/8592
    // Sentry.captureUnderscoreErrorException(props);

    let returnedValue = <NextErrorComponent statusCode={props.statusCode} />;
    switch (props?.domainInfo?.name) {
        case "severalbrands.com":
            returnedValue = <ErrorPage domain={props.domainInfo} />; // severalbrands.com 404 page
            break;
        case "torts.com":
            returnedValue = <ErrorPageTorts domain={props.domainInfo} />; // torts.com 404 page

            break;
    }

    return returnedValue;
};

CustomErrorComponent.getInitialProps = async (contextData) => {
    // In case this is running in a serverless function, await this in order to give Sentry
    // time to send the error before the lambda exits
    const { req, res, err } = contextData;
    const errorInitialProps = await NextErrorComponent.getInitialProps({
        res,
        err,
    });

    if (res?.statusCode === 404) {
        if (!res.req.isBlocked) {
            void postNotFoundPage({ req });
        }

        const keys = ["ts", "k", "c", "g"];
        if (
            keys.find((key) => res?.req?.originalUrl?.includes(`${key}=`)) &&
            !res.req.isBlocked &&
            res.req._parsedOriginalUrl?.pathname !== "/"
        ) {
            res.setHeader(
                "Location",
                `/?${res?.req?._parsedOriginalUrl?.query ?? ""}`,
            );
            res.statusCode = 302;
            res.end();
        }
        const domainName = req.headers.host;
        const domainErrorPage = customErrorPageDomains.find((name) =>
            domainName.includes(name),
        );
        if (domainErrorPage) {
            const domainData = await initialCall(
                "404",
                contextData,
                domainErrorPage,
            );
            return {
                ...errorInitialProps,
                domainInfo: domainData.props.domain,
            };
        }
        return errorInitialProps;
    }

    await captureUnderscoreErrorException(contextData);

    // This will contain the status code of the response
    return errorInitialProps;
};

export default CustomErrorComponent;
